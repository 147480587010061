import { makeStyles, shorthands } from '@fluentui/react-components';
import { FC } from 'react';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { ChatWindow } from '../chat/ChatWindow';
import { ChatList } from '../chat/chat-list/ChatList';

/* Winning customization to allow automatic auth of MsGraphPlugin */
import { useIsAuthenticated } from '@azure/msal-react';
import { useMsGraphAutoAuth } from '../../libs/hooks/useMsGraphAutoAuth';

const useClasses = makeStyles({
    container: {
        ...shorthands.overflow('hidden'),
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'start',
        height: '100%',
    },
});

export const ChatView: FC = () => {
    const classes = useClasses();
    const { selectedId } = useAppSelector((state: RootState) => state.conversations);
    const isAuthenticated = useIsAuthenticated();

    /* Winning customization to allow auto-auth of Ms Graph plugin */
    useMsGraphAutoAuth(isAuthenticated);

    return (
        <div className={classes.container}>
            <ChatList />
            {selectedId !== '' && <ChatWindow />}
        </div>
    );
};
