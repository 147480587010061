/*
  Winning customization to allow automatic graph plugin authentication
*/

import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { TokenHelper } from '../auth/TokenHelper';
import { BuiltInPlugins } from '../../redux/features/plugins/PluginsState';
import { connectPlugin } from '../../redux/features/plugins/pluginsSlice';

export const useMsGraphAutoAuth = (isAuthenticated: boolean) => {
    const { instance, inProgress } = useMsal();
    const dispatch = useAppDispatch();
    const plugins = useAppSelector((state: RootState) => state.plugins.plugins);

    useEffect(() => {

        // If not authenticated, do nothing
        if (!isAuthenticated) {
            return;
        }

        const msGraphPlugin = plugins[BuiltInPlugins.MsGraph];

        const getOrRefreshTokenIfNeeded = () => {
            TokenHelper.getAccessTokenUsingMsal(
                inProgress,
                instance,
                msGraphPlugin.authRequirements.scopes ?? []
            )
                .then((token) => {
                    dispatch(
                        connectPlugin({
                            plugin: msGraphPlugin.name,
                            accessToken: token,
                        }),
                    );
                })
                .catch((error) => {
                    console.warn('Failed to auto-authenticate MS Graph plugin:', error);
                });
        };

        getOrRefreshTokenIfNeeded(); // Initial get token
        const intervalId = setInterval(getOrRefreshTokenIfNeeded, 15 * 60 * 1000); // Check every 15 minutes

        return () => { clearInterval(intervalId) }; // Cleanup on unmount
    }, [isAuthenticated, instance, inProgress, plugins, dispatch]);
}; 